import { Title, Wrappers } from "../../../components/elements/UserContentTemplete";
import SignUpCard from "../../../components/SignUpCard";
const SignUp = () =>{
    return (
        <>
        <Wrappers className="wrapper">
            <SignUpCard/>
        </Wrappers>
        </>
    )
}
export default SignUp;