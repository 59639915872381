import { useEffect } from "react";
import ResignCard from "../../../components/ResignCard";
import { Title, Wrappers } from "../../../components/elements/UserContentTemplete";

const Resign = () =>{
    useEffect(()=>{
        
    },[])
    return (
        <>
        <Wrappers>
            <ResignCard/>
        </Wrappers>
        </>
    )
}
export default Resign;