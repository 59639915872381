import { Title, Wrappers } from "../../../components/elements/UserContentTemplete";
import LoginCard from "../../../components/LoginCard";

const Login = () =>{
    return (
        <>
        <Wrappers className="wrapper">
            <LoginCard/>
        </Wrappers>
        </>
    )
}
export default Login;