import { useEffect } from "react";
import EditMyInfoCard from "../../../components/EditMyInfoCard";
import { Title, Wrappers } from "../../../components/elements/UserContentTemplete";

const EditMyInfo = () =>{
    useEffect(()=>{
        
    },[])
    return (
        <>
        <Wrappers>
            <EditMyInfoCard/>
        </Wrappers>
        </>
    )
}
export default EditMyInfo;